@import "vars";

#main {
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  vertical-align: top;
  position: relative;
  min-height: calc(100vh - 9px);
  margin: 0;
  padding: 0;
  @media screen and (max-width: 660px) {
    flex-direction: column;
    min-height: 100vh;
  }
}

#termsFooter {
  width: 400px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media screen and (max-width: 880px) {
    width: 100%;
  }

  @media screen and (max-width: 660px) {
    flex: 1 0 auto;
    justify-content: flex-start;
    padding-top: 1rem;
  }

  a {
    color: var(--blue);
  }
}

#leftPane {
  width: 50%;
  vertical-align: top;
  min-height: 100vh;
  flex: 0 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @media screen and (max-width: 690px) {
    width: 100%;
    min-height: unset;
  }
}

#rightPane {
  background: linear-gradient(90deg, #2c6f3b 0%, #1e7531 51.04%, #2c6739 100%);
  width: 50%;
  vertical-align: top;
  min-height: 100vh;
  flex: 0 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 690px) {
    display: none;
  }

  #loginIcon {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  #loginIconText {
    margin-top: auto;
    margin-bottom: 120px;
    height: 15%;
  }

  h3 {
    color: white;
    font-size: 33px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;

    @media screen and (max-width: 900px) {
      font-size: 26px;
    }
  }

  #rightContent {
    height: 100%;
    width: 545px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      width: 100%;
    }
  }
}

#logo {
  h1 {
    font-size: 23px;
    font-weight: 400;
    line-height: 35px;
    //text-align: center;
    margin-top: 17px;
    margin-bottom: 40px;

    @media screen and (max-width: 755px) {
      font-size: 19px;
    }

    @media screen and (max-width: 690px) {
      font-size: 33px;
      font-weight: 500;
      line-height: 50px;
      text-align: center;
    }
  }

  h2 {
    font-size: 36px;
    font-weight: 400;
    line-height: 41px;
    text-align: left;
    color: rgba(0, 0, 0, 0.5);

    @media screen and (max-width: 890px) {
      font-size: 26px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    width: 65px;
  }

  width: 400px;
  margin: 0 auto 2rem;
  text-align: center;
  padding-top: calc(2rem + 4px);
  height: 40%;
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  div {
    width: 100%;
  }
}

#otpIframe {
  border: none;
  width: 100%;
  height: 75px;
}

#loginArea {
  width: 400px;
  height: 30%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  //margin: auto;

  @media screen and (max-width: 900px) {
    width: 100%;
  }

  input:not([type="submit"]),
  select {
    font-size: 16px;
    cursor: text;
    margin-top: 0;
    display: block;
    width: 100%;
    padding: 7px;
    background: none;
    border: none;
    border-bottom: 1px solid black;
    transition: all 0.2s ease-in;
    border-radius: 0;
    box-shadow: none !important;
  }
  input:focus:not([type="submit"]) {
    border-bottom: 1px solid var(--mainColor);
    outline: none;
  }

  #loginButton {
    direction: ltr;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
    // height: 50px;
    background: linear-gradient(90deg, #2c6f3b 0%, #1e7531 51.04%, #2c6739 100%);
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    margin-top: 12px;
    span {
      margin: 0 auto;
      font-size: 20px;
      font-weight: 300;
      @media screen and (max-width: 460px) {
        font-size: 5vw;
      }

      @media screen and (max-width: 290px) {
        font-size: 4.5vw;
      }
    }
    img {
      margin-right: 10px;
    }
  }
}

.countryCode {
  padding-left: 12px !important;
}

#countryCodes {
  position: relative;

  #countryCodesDropdown {
    position: absolute;
    left: 0;
    width: 250px;
    bottom: 0;
    overflow-y: scroll;
    max-height: 150px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 10px 25px 0 rgb(0, 0, 0, 0.1);
    transform: translateY(100%);
  }

  .countryCodeItem {
    padding: 0.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    cursor: pointer;
    transition: all 0.2s ease-in;
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
  }
}

#mobilePhoneField {
  direction: ltr;
  display: flex;
  align-items: flex-start;
  label {
    width: calc(100% - 75px);
  }
  label:first-of-type {
    width: 70px;
    margin-right: 10px;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      right: -6px;
      height: 15px;
      width: 1px;
      background: black;
      bottom: 10px;
      opacity: 0.5;
    }
  }
}

#phoneNumberLabel {
  margin-bottom: 0;
}

#dropdownIcon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  right: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000;
}

.countryPlus {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

#resendCode {
  display: block;
  margin-bottom: 19px;
  color: var(--blue);
  font-weight: 300;
  //text-align: center;
}

#loginAnimation {
  position: relative;

  #lockAnimation {
    transform-origin: center center !important;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.3s ease-in;

    #top {
      width: 25px;
      transform-origin: right;

      z-index: 0;
      transition: all 0.2s ease-in;

      transform: rotate(0deg);
      margin-right: 0;
      margin-bottom: 0;
    }

    #bottom {
      z-index: 1;
    }
  }

  canvas {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  p {
    display: block;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
    margin-top: 200px;
  }
}

.locked {
  transform: rotate(20deg) !important;
  margin-bottom: -5px !important;
  margin-right: -5px !important;
}

#shield {
  position: absolute;
  left: 50%;
  top: 50%;
  transform-origin: center center;
  transform: scale(0.8) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.2s ease-in;
}

.showSheild {
  transform: scale(1) translate(-50%, -50%) !important;
  opacity: 1 !important;
}

.hideLock {
  transform-origin: center center !important;
  transform: scale(0.9) translate(-50%, -50%) !important;
  opacity: 0;
}

#bottomLogin {
  //position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-image: url("/images/wave.svg");
  background-size: cover;
  height: 144px;
  display: none;
  flex-direction: column;
  justify-content: center;
  flex: 0;
  margin-top: auto;

  p {
    margin-top: 3rem;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    color: white;
  }

  @media screen and (max-width: 690px) {
    display: flex;
    p {
      margin-top: 4rem;
      margin-bottom: 40px;
      font-size: 17px;
      font-weight: 400;
      line-height: 26px;
      text-align: center;
      color: white;
    }
  }
}

#loginError {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--red);
  margin-bottom: 14px;
  margin-top: -10px;
  a {
    color: var(--blue);
  }
}

#otpForm {
  // @media screen and (max-width: 660px) {
  //   margin-top: -4rem !important;
  // }

  #otpInstructions {
    color: var(--labelColor);
    font-size: 18px;
    margin-bottom: 9px;
    font-weight: 300;
    line-height: 26px;

    @media screen and (max-width: 660px) {
      font-size: 17px;
    }
  }
}
